import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Supports} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

const SupportsScreen = () => {
	const [supports, setSupports] = useState(null);
	useEffect(() => {
		const supportsGet = async () => {
			const d = await Supports.getAll();
			const data = [], fb = [];
			d.data.forEach((v) => {
				if (data[v.clientId]) data[v.clientId].count = (data[v.clientId].count || 1) + 1;
				else data[v.clientId] = v
			});
			const messages = ls('messages') || [];
			data.forEach((v) => {
				const isnew = messages[v.clientId] ? messages[v.clientId].count !== v.count : false;
				v.isnew = isnew;
				fb.push(v);
			});
			fb.sort((a,b) => a.isnew ? -1 : 1);
			setSupports(dataSet(fb));
		};
		supportsGet();
		const interval = setInterval(() => supportsGet(), 1000);
		return () => clearInterval(interval);
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'supports', ['dateCreate','clientId','clientName','count','isnew']);
	return (
		<Template>
			{supports === null ? <Loader /> :
				<DataGrid
					title={'Поддержка'} 
					keys={['Дата','ID Клиента','Имя','Сообщений','Новые']}
					link={'support'}
					data={supports}
					dataFull={supports}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default SupportsScreen;